import { render, staticRenderFns } from "./SelfcheckMeteoropathyRes.vue?vue&type=template&id=6f52c960&scoped=true&"
import script from "./SelfcheckMeteoropathyRes.vue?vue&type=script&lang=js&"
export * from "./SelfcheckMeteoropathyRes.vue?vue&type=script&lang=js&"
import style0 from "./SelfcheckMeteoropathyRes.vue?vue&type=style&index=0&id=6f52c960&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f52c960",
  null
  
)

export default component.exports