<template>
<div class="selfcheck-container" id="js_selfcheck_container">
	<article class="selfcheck meteoropath res">
		<h1 class="selfcheck__header" id="js_observer">
			<img src="@/assets/img/selfcheck-meteoropathy/title01.png" alt="あなたは大丈夫？ 医師監修 気象病セルフチェック">
			<p><span>presented by</span><i class="selfcheck__header--icon sprite_meteoropathy logo" /></p>
		</h1>

		<!-- 結果 -->
		<section class="selfcheck__result" v-if="result === '2'">
			<h2 class="selfcheck__result--title"><i class="sprite_meteoropathy result2" /></h2>
			<p class="selfcheck__result--text">あなたの体調不良は気象病の可能性があります。頭痛ーるの気圧予報で体調不良と天気の変化が関係しているか確認してみることをオススメします。</p>
		</section>
		<section class="selfcheck__result" v-else-if="result === '1'">
			<h2 class="selfcheck__result--title"><i class="sprite_meteoropathy result1" /></h2>
			<p class="selfcheck__result--text">あなたの体調不良は気象病の可能性があるかもしれません。原因不明の体調不良のときは、頭痛ーるの気圧予報で天気が関係しているか確認してみることをオススメします。</p>
		</section>
		<section class="selfcheck__result" v-else-if="result === '0'">
			<h2 class="selfcheck__result--title"><i class="sprite_meteoropathy result0" /></h2>
			<p class="selfcheck__result--text">いまのところ気象病の可能性は低いですが、体調は日々変化します。もし体調が悪くなったら頭痛ーるをみて気圧が影響しているか確かめてみましょう。</p>
		</section>
		<section class="selfcheck__result" v-else>
			<h2 class="selfcheck__result--title">結果の取得に失敗しました。</h2>
		</section>
		<!-- /結果 -->

		<!-- アプリダウンロードボタン -->
		<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="selfcheck__app-download" id="trackingSelfcheckAppDownloadAndroidBtn01"><img src="@/assets/img/selfcheck-meteoropathy/btn01.png" alt="頭痛ーるアプリなら低気圧予報をプッシュ通知でお知らせ！ いますぐダウンロード"></a>
		<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="selfcheck__app-download" id="trackingSelfcheckAppDownloadIosBtn01"><img src="@/assets/img/selfcheck-meteoropathy/btn01.png" alt="頭痛ーるアプリなら低気圧予報をプッシュ通知でお知らせ！ いますぐダウンロード"></a>
		<!-- /アプリダウンロードボタン -->

		<!-- 解説 -->
		<section class="selfcheck__commentary" v-if="commentary.length > 0">
			<h2 class="selfcheck__commentary--title">
				<i class="sprite_meteoropathy icon" />
				<span>〇を選択した設問の医師解説</span>
			</h2>
			<section class="selfcheck__commentary--inner">
				<section class="selfcheck__commentary--item" v-show="commentary.indexOf('1') > -1 || commentary.indexOf('2') > -1">
					<h3 class="selfcheck__commentary--question">Q.1&nbsp;天候が変わる時に体の不調やメンタルの不調がある。<br>Q.2&nbsp;雨が降る前や天候が変わる前に、何となく予測が出来る。</h3>
					<p class="selfcheck__commentary--text">気象病の一番の特徴として、天候が変わるときに体調が悪くなることが挙げられます。また、体調が悪くなり始めることで、天気の変化がなんとなく予測できる場合もあります。</p>
				</section>
				<section class="selfcheck__commentary--item" v-show="commentary.indexOf('3') > -1">
					<h3 class="selfcheck__commentary--question">Q.3&nbsp;頭痛持ちである</h3>
					<p class="selfcheck__commentary--text">気象病の人の8割に頭痛（緊張型頭痛・片頭痛）がみられます。（2019年1年間のせたがや内科・神経内科クリニック受診データより）</p>
				</section>
				<section class="selfcheck__commentary--item" v-show="commentary.indexOf('4') > -1 || commentary.indexOf('5') > -1">
					<h3 class="selfcheck__commentary--question">Q.4&nbsp;肩（首）こりがある。猫背、そり腰があり、姿勢が悪い。<br>Q.5&nbsp;歯のくいしばりや、歯ぎしり、歯の治療が多い。</h3>
					<p class="selfcheck__commentary--text">首は頭を支える大事な役割をしています。首や肩がこりやすい人、歯のくいしばりなどで頭や首に余計な力が入りやすい人は、肩や首の筋肉が緊張しやすく、頭痛も出やすくなります。</p>
				</section>
				<section class="selfcheck__commentary--item" v-show="commentary.indexOf('6') > -1 || commentary.indexOf('7') > -1">
					<h3 class="selfcheck__commentary--question">Q.6&nbsp;めまいや耳鳴りが起こりやすい。<br>Q.7&nbsp;乗り物酔いをしやすい。</h3>
					<p class="selfcheck__commentary--text">耳の奥にある内耳がセンサーのような役割をしていて、脳の中枢にある自律神経に気圧の変化を伝えるため、頭痛、首肩こり、全身倦怠感、めまいが起こりやすい人は気圧の影響を受けやすい傾向にあります。</p>
				</section>
				<section class="selfcheck__commentary--item" v-show="commentary.indexOf('8') > -1">
					<h3 class="selfcheck__commentary--question">Q.8&nbsp;1日4時間以上、PCやスマートフォンを使う日が多い。</h3>
					<p class="selfcheck__commentary--text">デスクワークの人やスマートフォンをよく見る人は、首や肩に負担がかかりやすくこりやすい傾向にあり、天気の影響による頭痛も出やすくなります。</p>
				</section>
				<section class="selfcheck__commentary--item" v-show="commentary.indexOf('9') > -1 || commentary.indexOf('10') > -1">
					<h3 class="selfcheck__commentary--question">Q.9&nbsp;ストレッチや柔軟体操をすることが少ない。<br>Q.10&nbsp;年中エアコンが効いている環境にいることが多い。</h3>
					<p class="selfcheck__commentary--text">体を動かす機会が少なかったり、エアコンの効いている環境にいることが多い人は、自律神経のバランスが崩れやすく気象病の症状が出やすくなります。</p>
				</section>
				<section class="selfcheck__commentary--item" v-show="commentary.indexOf('11') > -1 || commentary.indexOf('12') > -1">
					<h3 class="selfcheck__commentary--question">Q.11&nbsp;日常的にストレスを感じている。特に精神的なストレス。<br>Q.12&nbsp;更年期障害（男女ともに）ではないかと思うことがある。</h3>
					<p class="selfcheck__commentary--text">心身のストレスがあると自律神経が乱れます。その影響で天気の変化に体が敏感になる人もいます。</p>
				</section>
			</section>
		</section>
		<!-- /解説 -->

		<!-- 監修者 -->
		<section class="selfcheck__supervisor">
			<h2 class="selfcheck__supervisor--title">
				<i class="sprite_meteoropathy supervisor" />
				<span><span class="is-large">監修</span><br>せたがや内科・神経内科クリニック<br>院長・医学博士<br><span class="is-large">久手堅 司（くでけん つかさ）</span></span>
			</h2>
			<p class="selfcheck__supervisor--text">日本内科学会&thinsp;総合内科専門医/日本神経学会&thinsp;神経内科専門医/日本頭痛学会&thinsp;頭痛専門医/日本脳卒中学会&thinsp;脳卒中専門医</p>
		</section>
		<!-- /監修者 -->

		<!-- アウトロ -->
		<section class="selfcheck__outro">
			<h2 class="selfcheck__outro--title">「気象病」による体調不良は、事前に予測ができます。頭痛ーるは、不調の原因となる「気圧の変化」をお知らせします！</h2>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="growthhack-f__with-zutool">
				<p class="growthhack-f__with-zutool--title">
					with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
				</p>
				<i class="growthhack-f__with-zutool--img sprite_meteoropathy with-zutool" />
			</div>
			<div class="about-rightnow__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingSelfcheckAppDownloadAndroidBtn02">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingSelfcheckAppDownloadIosBtn02">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>

			<nav class="selfcheck__outro--inner">
				<!-- 気象病セルフチェックにもどる -->
				<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckMeteoropathy`, _previousPage: previousPage } })" class="selfcheck__outro--selfchecktop">気象病セルフチェックにもどる</a>
				<!-- /気象病セルフチェックにもどる -->

				<!-- 特集TOPにもどる -->
				<a v-if="previousPage === 'rainyseason2023'" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'rainyseason2023' } })" class="selfcheck__outro--lptop">梅雨特集TOPへもどる</a>
				<!-- /特集TOPにもどる -->

				<!-- TOPにもどる -->
				<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="selfcheck__outro--sitetop">頭痛ーるTOPへ</a>
				<!-- TOPにもどる -->
			</nav>
		</section>
		<!-- /アウトロ -->

		<!-- 上部に戻るボタン -->
		<nav class="selfcheck__pagetop" id="js_pagetop_btn">
			<a href="javascript:void(0);" @click.prevent="onScroll('js_selfcheck_container')" class="selfcheck__pagetop--link">
				<img src="~@/assets/img/pagetop_btn01.png" alt="ページTOPにもどる">
			</a>
		</nav>
		<!-- /上部に戻るボタン -->
	</article>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

// Vuex
import { mapGetters } from 'vuex'

export default {
	name: 'SelfcheckMeteoropathyRes',
	components: {
	},
	data () {
		return {
			commentary: [],
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			pageTopObserver: null,
			previousPage: ''
		}
	},
	props: {
		result: {
			type: String,
			default: ''
		},
		answer: {
			type: String,
			default: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// チェックした設問を表示判定用の配列に格納
		if (this.answer) this.commentary = this.answer.split(',')

		// ページTOPに戻るボタン表示の処理
		const $target = document.getElementById('js_observer')
		const $pagetopBtn = document.getElementById('js_pagetop_btn')
		this.pageTopObserver = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry.isIntersecting) {
				$pagetopBtn.classList.remove('is-show')
			} else {
				$pagetopBtn.classList.add('is-show')
			}
		})
		this.pageTopObserver.observe($target)

		// $route.queryの_previousPageがあれば特集ページにもどるボタンを出しわけする
		if (this.$route.query._previousPage) this.previousPage = this.$route.query._previousPage
	},
	beforeDestroy () {
		// IntersectionObserverの監視を停止
		this.pageTopObserver.disconnect()
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-3: 0.187rem;
$spacing-5: 0.312rem;
$spacing-12: 0.75rem;
$spacing-13: 0.812rem;
$spacing-14: 0.875rem;
$spacing-17: 1.062rem;
$spacing-24: 1.5rem;
$spacing-28: 1.75rem;
$spacing-40: 2.5rem;
$spacing-50: 3.125rem;
$spacing-54_5: 3.406rem;
$font-size-11: 0.687rem;
$font-size-13: 0.812rem;
$font-size-15: 0.937rem;
$font-size-19: 1.187rem;
$selfcheck-primary: #0071DB;
$selfcheck-secondary: #00386D;
$background-supervisor: #F5F5F5;
$selfcheck-lptop-background: #FF61A0;
$selfcheck-lptop-border: #E6337B;
$line-height-1_2: 1.2;

.selfcheck {
	padding: 0;

	&__result {
		margin: $spacing-20 0 0 0;
		font-size: $font-size-15;
		color: $text-primary;

		&--title {
			margin: 0;
			overflow: hidden;
			text-align: center;
			font-weight: normal;

			& > i { margin: 0 auto; }
		}

		&--text {
			font-family: $lp-family-primary;
			margin: $spacing-13 0 0 0;
			padding: 0 $spacing-14;
			text-align: justify;
		}
	}

	&__app-download {
		display: block;
		width: 92.5334%;
		margin: $spacing-20 auto 0;

		& > img { width: 100%; }
	}

	&__commentary {
		width: 92.5334%;
		margin: $spacing-24 auto 0;
		padding: $spacing-20 0 0;
		border-top: 1px solid $line-primary;

		&--title {
			margin: 0;

			& > i { margin: 0 auto; }
			& > span {
				display: block;
				width: 100%;
				margin: $spacing-10 0 0 0;
				padding: $spacing-6 $spacing-10;
				box-sizing: border-box;
				border-radius: 19px;
				background: $selfcheck-primary;
				color: $text-seconday;
				font-size: $font-size-18;
				text-align: center;
				line-height: $line-height;
			}
		}

		&--item {
			padding: $spacing-20 0;
			border-bottom: 1px solid $line-primary;

			&::before {
				content: '〇を選択した設問';
				display: block;
				color: $selfcheck-primary;
				font-size: $font-size-13;
				font-weight: bold;
			}
		}

		&--question {
			margin: $spacing-5 0 0 0;
			font-family: $lp-family-primary;
			font-size: $font-size-15;
			font-weight: normal;
		}

		&--text {
			margin: $spacing-10 auto 0;
			padding: $spacing-12 $spacing-14;
			border: 2px solid $selfcheck-primary;
			border-radius: 10px;
			font-family: $lp-family-primary;
			font-size: $font-size-15;

			&::before {
				content: '解説';
				display: block;
				margin: 0 0 $spacing-4 0;
				color: $selfcheck-primary;
				font-family: $lp-family-tertiary;
				font-size: $font-size-13;
			}
		}
	}

	&__supervisor {
		width: 92.5334%;
		margin: $spacing-20 auto 0;
		padding: $spacing-20;
		box-sizing: border-box;
		font-family: $lp-family-primary;
		background-color: $background-supervisor;
		border-radius: 4px;

		&--title {
			display: flex;
			align-items: center;
			margin: 0;

			& > span {
				display: block;
				margin-left: $spacing-10;
				font-size: $font-size-11;
				font-weight: normal;
			}
			.is-large { font-size: $font-size-14; }
		}

		&--text {
			margin: $spacing-14 0 0;
			font-size: $font-size-13;
			text-align: justify;
		}
	}

	&__outro {
		margin: $spacing-40 0 0;

		&--title {
			padding: 0 $spacing-14;
			font-family: $lp-family-primary;
			font-size: $font-size-15;
			font-weight: normal;
		}

		.growthhack-f__caution { margin: $spacing-20 auto 0; }

		.about-rightnow__login { padding: 0; }

		&--inner {
			width: 100%;
			margin: $spacing-40 auto 0;
			padding: $spacing-50 0 0;
			border-top: 1px solid $line-primary;

			& > a {
				display: block;
				box-sizing: border-box;
				text-decoration: none;
				text-align: center;
				color: $text-seconday;
			}
		}

		&--selfchecktop {
			width: 80%;
			margin: 0 auto;
			padding: $spacing-17 0;
			background: $selfcheck-primary;
			border-radius: $spacing-6;
			font-size: $font-size-19;
			box-shadow: 0 3px $selfcheck-secondary;

			@media (max-width: (374px)) { font-size: $font-size-16; }
			@media (max-width: (330px)) { font-size: $font-size-14; }
		}

		&--lptop {
			width: 80%;
			margin: $spacing-50 auto 0;
			padding: $spacing-10 0;
			font-family: $lp-family-secondary;
			font-size: $font-size-18;
			line-height: $line-height-1_2;
			background-color: $selfcheck-lptop-background;
			border-radius: $spacing-6;
			box-shadow: 0 $spacing-3 $selfcheck-lptop-border;
		}

		&--sitetop {
			font-family: $lp-family-secondary;
			width: 61.3334%;
			margin: $spacing-30 auto 0;
			padding: $spacing-10 0;
			border-radius: $spacing-26;
			font-size: $font-size-24;
			background-color: $primary;
		}
	}

	/* ページTOPに戻るボタン */
	&__pagetop {
		position: sticky;
		width: 100%;
		margin: -$spacing-54_5 auto 0;
		padding: 0 $spacing-14;
		box-sizing: border-box;
		bottom: 0;
		opacity: 0;
		transition: opacity .3s ease-in;
		pointer-events: none;

		&.is-show { opacity: 1; }

		&--link {
			display: block;
			width: 14.1211%;
			margin-left: auto;
			pointer-events: auto;

			& > img {
				display: block;
				width: 100%;
			}
		}
	}

	.sprite_meteoropathy {
		background-image: url(~@/assets/img/selfcheck-meteoropathy/sprite_res.png);
		background-size: (704px / 2) (2917px / 2);
		background-repeat: no-repeat;
		display: block;

		&.icon {
			width: (120px / 2);
			height: (120px / 2);
			background-position: (-5px / 2) (-5px / 2);
		}

		&.img {
			width: (372px / 2);
			height: (210px / 2);
			background-position: (-5px / 2) (-135px / 2);
		}

		&.result2 {
			width: (694px / 2);
			height: (352px / 2);
			background-position: (-5px / 2) (-355px / 2);

			@media (max-width: (360px)) {
				width: (592px / 2);
				height: (300px / 2);
				background-position: (-5px / 2) (-717px / 2);
			}
		}

		&.result1 {
			width: (694px / 2);
			height: (387px / 2);
			background-position: (-5px / 2) (-1027px / 2);

			@media (max-width: (360px)) {
				width: (592px / 2);
				height: (330px / 2);
				background-position: (-5px / 2) (-1424px / 2);
			}
		}

		&.result0 {
			width: (694px / 2);
			height: (355px / 2);
			background-position: (-5px / 2) (-1764px / 2);

			@media (max-width: (360px)) {
				width: (592px / 2);
				height: (303px / 2);
				background-position: (-5px / 2) (-2129px / 2);
			}
		}

		&.logo {
			width: (157px / 2);
			height: (40px / 2);
			background-position: (-5px / 2) (-2442px / 2);
		}

		&.supervisor {
			width: (144px / 2);
			height: (144px / 2);
			background-position: (-5px / 2) (-2492px / 2);
		}

		&.with-zutool {
			width: (224px / 2);
			height: (266px / 2);
			background-position: (-5px / 2) (-2646px / 2);
		}
	}
}
</style>
